import { CalculationRule, ProductMainGroup, RewardProduct } from '../../../lib/types/calculator';
import { ProductCategory } from '../../../lib/models/Product.model';
import { WestCalculatorUrls } from '../../../lib/constants/react-router';

// 2025 BayerValue West Segments
export enum BayerValueWestRuleId {
  SEED_TREATMENT = 'westSeedTreatmentRewards',
  EMESTO = 'westEmestoBonus',
  SEGMENT_SAVINGS = 'westSegmentSavings',
  PRE_BURN_TANK_MIX = 'westPreBurnTankMix',
  INCREDIBLE_BAYER_OFFER = 'westIncredibleBayerOffer',
  FIELD_VIEW = 'westFieldViewRewards',
  TRAIT_REWARDS = 'westTraitRewards',
}

/**
 * A) Seed Treatment Rewards
 * 500 acres of eligible Seed Treatment Brands,
 * pays 5% of BayerValue Price on all eligible payment products within each
 * product segment that reaches a minimum of 300 acres each.
 */
const seedTreatmentConditions = {
  group: ProductMainGroup.SEED_TREATMENT,
  qualifyingAcres: 500,
  rewardPercentage: 0.05,
  qualifiers: {
    seedTreatment: 500,
    fungicides: 300,
    herbicides: 300,
  },
};

const seedTreatmentRule: CalculationRule = {
  id: BayerValueWestRuleId.SEED_TREATMENT,
  condition: (products, segmentQualification) => {
    const qualifyingAcres = products.reduce((sum, product) => {
      let multiplier = 0;

      if (segmentQualification.doubleQualifierProducts.includes(product.sku)) {
        multiplier = 2;
      } else if (segmentQualification.qualifierProducts.includes(product.sku)) {
        multiplier = 1;
      }

      if (segmentQualification.segmentRequirement.includes(product.sku) && multiplier > 0) {
        return sum + product.acres * multiplier;
      }

      return sum;
    }, 0);

    return qualifyingAcres >= seedTreatmentConditions.qualifyingAcres;
  },
  calculate: (products, segmentQualification, qualifyingAcres) => {
    const { qualifiers, rewardPercentage } = seedTreatmentConditions;
    const { herbicides, fungicides, seedTreatment, insecticides } = qualifyingAcres;
    const { paymentProduct } = segmentQualification;
    const eligibleProducts: RewardProduct[] = [];
    let totalReward = 0;

    products.forEach((product) => {
      if (!paymentProduct.includes(product.sku)) return;

      let isEligible = false;

      switch (product.group) {
        case ProductMainGroup.FUNGICIDES:
          isEligible = fungicides >= qualifiers.fungicides;
          break;
        case ProductMainGroup.HERBICIDES:
          isEligible = herbicides >= qualifiers.herbicides;
          break;
        case ProductMainGroup.INSECTICIDE:
        case ProductMainGroup.SEED_TREATMENT:
          isEligible = seedTreatment + insecticides >= qualifiers.seedTreatment;
          break;
        default:
          break;
      }

      if (isEligible) {
        const estimatedProductReward: number =
          product.pricePerAcre * product.acres * rewardPercentage;
        totalReward += estimatedProductReward;
        eligibleProducts.push({ ...product, estimatedProductReward });
      }
    });

    return {
      [BayerValueWestRuleId.SEED_TREATMENT]: {
        reward: totalReward,
        products: eligibleProducts,
        programUrl: WestCalculatorUrls.BAYER_VALUE,
      },
    };
  },
};

/**
 * B) Emesto Bonus
 * 500 acres of Emesto Silver and/or Emesto Complete,
 * pays 5% of BayerValue Price on all eligible payment products.
 */
const emestoConditions = {
  qualifyingAcres: 500,
  rewardPercentage: 0.05,
  qualifiers: {
    fungicides: 300,
  },
};

const emestoRule: CalculationRule = {
  id: BayerValueWestRuleId.EMESTO,
  condition: (products, segmentQualification) => {
    const qualifyingAcres = products.reduce((sum, product) => {
      if (segmentQualification.segmentRequirement.includes(product.sku)) {
        return sum + product.acres;
      }

      return sum;
    }, 0);

    return qualifyingAcres >= emestoConditions.qualifyingAcres;
  },
  calculate: (products, segmentQualification, qualifyingAcres) => {
    const { fungicides } = qualifyingAcres;
    const { paymentProduct } = segmentQualification;
    const eligibleProducts: RewardProduct[] = [];
    let totalReward = 0;

    products.forEach((product) => {
      if (!paymentProduct.includes(product.sku)) return;

      if (
        product.group === ProductMainGroup.FUNGICIDES &&
        fungicides < emestoConditions.qualifiers.fungicides
      ) {
        return;
      }

      const estimatedProductReward =
        product.acres * product.pricePerAcre * emestoConditions.rewardPercentage;
      totalReward += estimatedProductReward;
      eligibleProducts.push({ ...product, estimatedProductReward });
    });

    return {
      [BayerValueWestRuleId.EMESTO]: {
        reward: totalReward,
        products: eligibleProducts,
        programUrl: WestCalculatorUrls.EMESTO,
      },
    };
  },
};

/**
 * C) Trait Rewards
 */
const traitRewardsCondition = {
  group: ProductMainGroup.TRAIT,
  qualifyingTraitAcres: 500,
  qualifyingSegmentAcres: 300,
  rewardPercentage: 0.05,
};

const traitRewardsRule: CalculationRule = {
  id: BayerValueWestRuleId.TRAIT_REWARDS,
  condition: (products, segmentQualification) => {
    const { qualifyingTraitAcres } = traitRewardsCondition;
    const qualifyingAcres = products.reduce((sum, product) => {
      if (segmentQualification.segmentRequirement.includes(product.sku)) {
        return sum + product.acres;
      }

      return sum;
    }, 0);

    return qualifyingAcres >= qualifyingTraitAcres;
  },
  calculate: (products, segmentQualification, qualifyingAcres) => {
    const { qualifyingSegmentAcres, rewardPercentage } = traitRewardsCondition;
    const { herbicides, fungicides, seedTreatment, insecticides } = qualifyingAcres;
    const { paymentProduct } = segmentQualification;
    const eligibleProducts: RewardProduct[] = [];
    let totalReward = 0;

    products.forEach((product) => {
      if (!paymentProduct.includes(product.sku)) return;

      const { group, pricePerAcre, acres } = product;

      const productGroups: Record<ProductMainGroup, number> = {
        [ProductMainGroup.FUNGICIDES]: fungicides,
        [ProductMainGroup.HERBICIDES]: herbicides,
        [ProductMainGroup.INSECTICIDE]: seedTreatment + insecticides,
        [ProductMainGroup.SEED_TREATMENT]: seedTreatment + insecticides,
        [ProductMainGroup.TRAIT]: 0,
      };

      if ((productGroups[group as ProductMainGroup] ?? 0) >= qualifyingSegmentAcres) {
        const estimatedProductReward = pricePerAcre * acres * rewardPercentage;
        totalReward += estimatedProductReward;
        eligibleProducts.push({ ...product, estimatedProductReward });
      }
    });

    return {
      [BayerValueWestRuleId.TRAIT_REWARDS]: {
        reward: totalReward,
        products: eligibleProducts,
      },
    };
  },
};

/**
 * D) Segment Savings
 * Segment Eligibility:
 * 1. 300 acres of any combination of brands from each of 2 segments pays
 *    5% of BVP on eligible Seed Treatment/Insecticide, Herbicide, and Fungicide
 *    products only if the segment has a min of 300 acres of eligible product purchases.
 *
 * 2. 300 acres of any combination of brands from each of 3 segments pays
 *    10% of BVP on eligible Seed Treatment/Insecticide, Herbicide, and Fungicide
 *    products only if the segment has a min of 300 acres of eligible product purchases.
 */
const segmentSavingConditions = {
  qualifyingAcres: 300,
  qualifyingSegments: 2,
  rewardPercentageTwoSegment: 0.05,
  rewardPercentageThreeSegment: 0.1,
};

const segmentSavingRule: CalculationRule = {
  id: BayerValueWestRuleId.SEGMENT_SAVINGS,
  condition: (_, __, qualifyingAcres) => {
    const { herbicides, fungicides, seedTreatment, insecticides } = qualifyingAcres;
    const segmentList = [herbicides, fungicides, seedTreatment + insecticides];

    const qualifyingSegments = segmentList.reduce((sum, acres) => {
      if (acres >= segmentSavingConditions.qualifyingAcres) {
        return sum + 1;
      }

      return sum;
    }, 0);

    return qualifyingSegments >= segmentSavingConditions.qualifyingSegments;
  },
  calculate: (products, segmentQualification, qualifyingAcres) => {
    const { herbicides, fungicides, seedTreatment, insecticides } = qualifyingAcres;
    const { paymentProduct } = segmentQualification;
    const segmentList = [herbicides, fungicides, seedTreatment + insecticides];

    // Count how many segments qualify
    const qualifyingSegments = segmentList.reduce(
      (sum, acres) => (acres >= segmentSavingConditions.qualifyingAcres ? sum + 1 : sum),
      0
    );

    // Determine bonus percentage based on the number of qualifying segments
    let bonusPercentage = 0;

    if (qualifyingSegments === 3) {
      bonusPercentage = segmentSavingConditions.rewardPercentageThreeSegment;
    } else if (qualifyingSegments === 2) {
      bonusPercentage = segmentSavingConditions.rewardPercentageTwoSegment;
    }

    // Conditions for each product group
    const groupConditions: Record<ProductMainGroup, () => boolean> = {
      [ProductMainGroup.FUNGICIDES]: () => fungicides >= segmentSavingConditions.qualifyingAcres,
      [ProductMainGroup.HERBICIDES]: () => herbicides >= segmentSavingConditions.qualifyingAcres,
      [ProductMainGroup.INSECTICIDE]: () =>
        insecticides + seedTreatment >= segmentSavingConditions.qualifyingAcres,
      [ProductMainGroup.SEED_TREATMENT]: () =>
        seedTreatment + seedTreatment >= segmentSavingConditions.qualifyingAcres,
      [ProductMainGroup.TRAIT]: () => false,
    };

    const eligibleProducts: RewardProduct[] = [];
    let totalReward = 0;

    products.forEach((product) => {
      const condition = groupConditions[product.group as ProductMainGroup];
      if (condition && condition() && paymentProduct.includes(product.sku)) {
        const estimatedProductReward = product.acres * product.pricePerAcre * bonusPercentage;
        totalReward += estimatedProductReward;
        eligibleProducts.push({ ...product, estimatedProductReward });
      }
    });

    return {
      [BayerValueWestRuleId.SEGMENT_SAVINGS]: {
        reward: totalReward,
        products: eligibleProducts,
        programUrl: WestCalculatorUrls.BAYER_VALUE,
      },
    };
  },
};

/**
 * E) The Incredible Bayer Offer
 * Purchases a minimum of 1,000 acres of qualifying herbicide or seed treatments, pays
 * 5% on qualifying payable seed treatments or 5% on qualifying payable herbicides
 */
const incredibleBayerOfferConditions = {
  qualifyingAcres: 1000,
  rewardPercentage: 0.05,
};

const incredibleBayerOfferRule: CalculationRule = {
  id: BayerValueWestRuleId.INCREDIBLE_BAYER_OFFER,
  condition: (_, __, qualifyingAcres) => {
    const { herbicides, seedTreatment } = qualifyingAcres;

    return herbicides + seedTreatment >= incredibleBayerOfferConditions.qualifyingAcres;
  },
  calculate: (products, segmentQualification) => {
    const { paymentProduct } = segmentQualification;
    const eligibleProducts: RewardProduct[] = [];
    let totalReward: number = 0;

    products.forEach((product) => {
      if (paymentProduct.includes(product.sku)) {
        const estimatedProductReward =
          product.acres * product.pricePerAcre * incredibleBayerOfferConditions.rewardPercentage;
        totalReward += estimatedProductReward;
        eligibleProducts.push({ ...product, estimatedProductReward });
      }
    });

    return {
      [BayerValueWestRuleId.INCREDIBLE_BAYER_OFFER]: {
        reward: totalReward,
        products: eligibleProducts,
        programUrl: WestCalculatorUrls.INCREDIBLE_BAYER_OFFER,
      },
    };
  },
};

/**
 * F) FieldView Rewards
 * Qualifies for a minimum of 2 Crop Protection segments or
 * Qualifies for a minimum of 500 acres of Bayer brand seed and/or Bayer trait and
 * Then Bayer will rebate the qualifying grower 50% of the cost of their FieldView
 * subscription on the 2025 BayerValue rebate cheque.
 */
const fieldViewRebateConditions = {
  minimumCropProtectionSegments: 2,
  minimumAcresCropProtection: 300,
  minimumBayerSeedAcres: 500,
  rebatePercentage: 0.5,
  subscriptionCost: 500,
};

const fieldViewRebateRule: CalculationRule = {
  id: BayerValueWestRuleId.FIELD_VIEW,
  condition: (_, __, qualifyingAcres) => {
    const { seedTreatment, herbicides, fungicides, trait } = qualifyingAcres;
    const { minimumAcresCropProtection, minimumCropProtectionSegments, minimumBayerSeedAcres } =
      fieldViewRebateConditions;

    const values = [seedTreatment, herbicides, fungicides];
    const qualifyingSegments = values.filter((value) => value >= minimumAcresCropProtection).length;

    return qualifyingSegments >= minimumCropProtectionSegments || trait >= minimumBayerSeedAcres;
  },
  calculate: () => {
    const { subscriptionCost, rebatePercentage } = fieldViewRebateConditions;
    const reward = subscriptionCost * rebatePercentage;

    return {
      [BayerValueWestRuleId.FIELD_VIEW]: {
        reward,
        products: [],
      },
    };
  },
};

/**
 * G) Pre-burn Tank Mix Bonus Conditions
 * Product Eligibility
 * 300 acres of any combination of:
 * Huskie Pre Herbicide (All SKU's) = 20 acres per smallest saleable unit.
 * Pardner Herbicide (All SKU's) = 20 acres per smallest saleable unit.
 * Olympus Herbicide (All SKU's) = 80 acres per smallest saleable unit.
 *                                  +
 * A minimum of 300 acres of Roundup Transorb HC Herbicide
 */
const preBurnTankMixConditions = {
  minimunAcres: 300,
};

const preBurnTankMixRule: CalculationRule = {
  id: BayerValueWestRuleId.PRE_BURN_TANK_MIX,
  condition: (products, segmentQualification) => {
    const { segmentRequirement } = segmentQualification;
    const { minimunAcres } = preBurnTankMixConditions;

    const { combinedAcres, mandatoryAcres } = products.reduce(
      (totals, product) => {
        if (segmentRequirement.includes(product.sku)) {
          if (product.crop === ProductCategory.CANOLA_HYBRID) {
            return { ...totals, mandatoryAcres: totals.mandatoryAcres + product.acres };
          }
          return { ...totals, combinedAcres: totals.combinedAcres + product.acres };
        }
        return totals;
      },
      { combinedAcres: 0, mandatoryAcres: 0 }
    );
    return combinedAcres >= minimunAcres && mandatoryAcres >= minimunAcres;
  },
  calculate: (products, segmentQualification) => {
    const { paymentProduct } = segmentQualification;
    let eligibleProducts: RewardProduct[] = [];

    const { combinedAcres, mandatoryAcres } = products.reduce(
      (totals, product) => {
        if (paymentProduct.includes(product.sku)) {
          eligibleProducts.push(product);
          if (product.crop === ProductCategory.CANOLA_HYBRID) {
            return { ...totals, mandatoryAcres: totals.mandatoryAcres + product.acres };
          }
          return { ...totals, combinedAcres: totals.combinedAcres + product.acres };
        }
        return totals;
      },
      { combinedAcres: 0, mandatoryAcres: 0 }
    );

    const rewardAcres = Math.min(combinedAcres, mandatoryAcres);

    const combinedAcresReward = combinedAcres < mandatoryAcres;
    eligibleProducts = eligibleProducts.map((product) => {
      const shouldUpdateReward = combinedAcresReward
        ? product.crop !== ProductCategory.CANOLA_HYBRID
        : product.crop === ProductCategory.CANOLA_HYBRID;

      return shouldUpdateReward
        ? { ...product, estimatedProductReward: product.acres }
        : { ...product };
    });

    return {
      [BayerValueWestRuleId.PRE_BURN_TANK_MIX]: {
        reward: rewardAcres,
        products: eligibleProducts,
      },
    };
  },
};

export const calculationRules: CalculationRule[] = [
  emestoRule,
  incredibleBayerOfferRule,
  fieldViewRebateRule,
  preBurnTankMixRule,
  seedTreatmentRule,
  segmentSavingRule,
  traitRewardsRule,
];
