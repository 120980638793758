import { PreFillOption, RewardsBreakdown, SelectedProduct } from '../../types/calculator';
import { ProductQualification, Region } from '../../../__generated__/graphql';
import { ReactNode } from 'react';

export interface CalculatorContextState {
  preFill: PreFillOption;
  selectedCrops: string[];
  selectedProducts: SelectedProduct[];
  totalRewards: number;
  breakdown: RewardsBreakdown;
  totalCost: number;
  productQualifications: ProductQualification[];
  region?: Region;
}

export interface CalculatorContextProps {
  children: ReactNode;
}

export enum CalculatorContextActionTypes {
  UpdatePreferences = 'UpdatePreferences',
  UpdateProductQualifications = 'UpdateProductQualifications',
  AddSelectedProduct = 'AddSelectedProduct',
  UpdateSelectedProduct = 'UpdateSelectedProduct',
  RemoveSelectedProduct = 'RemoveSelectedProduct',
  ResetSelectedProduct = 'ResetSelectedProduct',
  UpdateProgramRegion = 'UpdateProgramRegion',
}

export type CalculatorContextAction =
  | {
      type: CalculatorContextActionTypes.UpdatePreferences;
      payload: {
        prefill: PreFillOption;
        selectedCrops: string[];
      };
    }
  | {
      type: CalculatorContextActionTypes.AddSelectedProduct;
      payload: SelectedProduct;
    }
  | {
      type: CalculatorContextActionTypes.UpdateSelectedProduct;
      payload: {
        index: number;
        product: Partial<SelectedProduct>;
      };
    }
  | {
      type: CalculatorContextActionTypes.RemoveSelectedProduct;
      payload: { index: number };
    }
  | {
      type: CalculatorContextActionTypes.ResetSelectedProduct;
    }
  | {
      type: CalculatorContextActionTypes.UpdateProductQualifications;
      payload: { productQualifications: ProductQualification[] };
    }
  | {
      type: CalculatorContextActionTypes.UpdateProgramRegion;
      payload: Region;
    };

export type CalculatorContextDispatch = (action: CalculatorContextAction) => void;

export type CalculatorContextType = [CalculatorContextState, CalculatorContextDispatch];
