import Accordion from '../../../../components/_shared/Accordion';
import { ChevronDownIcon } from '../../../../assets/icons';
import cx from 'classnames';
import { FC } from 'react';
import styles from './TotalBreakdown.module.scss';
import useCalculatorContext from '../../../../lib/contexts/calculator/useCalculatorContext';
import { useTranslation } from 'react-i18next';

const TotalBreakdown: FC = () => {
  const { t } = useTranslation();
  const [{ selectedProducts, totalCost, totalRewards }] = useCalculatorContext();

  return (
    <div className={styles['total-breakdown']}>
      <div className={styles['total-breakdown__running-total-wrapper']}>
        <span className={styles['total-breakdown__running-total-title']}>
          {t('bayer-rewards-calculator.total-amount-breakdown.estimated-rewards')}
        </span>
        <span className={styles['total-breakdown__running-total-amount']}>
          {t('format.price', { value: totalRewards })}
        </span>
      </div>
      <Accordion
        title={t('bayer-rewards-calculator.total-amount-breakdown.title')}
        defaultOpen
        Icon={ChevronDownIcon}
        classNames={{
          container: styles['total-breakdown__accordion-container'],
          button: styles['total-breakdown__accordion-button'],
        }}
      >
        <table className={styles['total-breakdown__table']}>
          <thead>
            <tr>
              <th
                scope="col"
                className={cx(
                  styles['total-breakdown__table-header'],
                  styles['total-breakdown__table-column--left']
                )}
              >
                {t('bayer-rewards-calculator.total-amount-breakdown.product')}
              </th>
              <th
                scope="col"
                className={cx(
                  styles['total-breakdown__table-header'],
                  styles['total-breakdown__table-header--acres']
                )}
              >
                {t('bayer-rewards-calculator.total-amount-breakdown.acres')}
              </th>
              <th
                scope="col"
                className={cx(
                  styles['total-breakdown__table-header'],
                  styles['total-breakdown__table-column--right']
                )}
              >
                {t('bayer-rewards-calculator.total-amount-breakdown.cost')}
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedProducts
              .filter((product) => !!product.sku)
              .map((product) => (
                <tr key={product.sku}>
                  <th
                    scope="row"
                    className={cx(
                      styles['total-breakdown__table-column--left'],
                      styles['total-breakdown__product-title']
                    )}
                  >
                    {product.name}
                  </th>
                  <td
                    className={cx(
                      styles['total-breakdown__product-amount'],
                      styles['total-breakdown__product-amount--acres']
                    )}
                  >
                    {product.acres}
                  </td>
                  <td
                    className={cx(
                      styles['total-breakdown__table-column--right'],
                      styles['total-breakdown__product-amount']
                    )}
                  >
                    {!!product.pricePerAcre &&
                      t('format.price', { value: product.acres * product.pricePerAcre })}
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot className={styles['total-breakdown__table-footer']}>
            <tr>
              <th
                scope="row"
                className={cx(
                  styles['total-breakdown__table-column--left'],
                  styles['total-breakdown__product-title'],
                  styles['total-breakdown__product-title--total']
                )}
              >
                {t('bayer-rewards-calculator.total-amount-breakdown.total')}
              </th>
              <td
                className={cx(
                  styles['total-breakdown__product-amount'],
                  styles['total-breakdown__product-amount--total'],
                  styles['total-breakdown__product-amount--acres']
                )}
              >
                {selectedProducts.reduce((acc, product) => product.acres + acc, 0)}
              </td>
              <td
                className={cx(
                  styles['total-breakdown__table-column--right'],
                  styles['total-breakdown__product-amount'],
                  styles['total-breakdown__product-amount--total']
                )}
              >
                {t('format.price', {
                  value: totalCost,
                })}
              </td>
            </tr>
          </tfoot>
        </table>
      </Accordion>
    </div>
  );
};

TotalBreakdown.displayName = 'TotalBreakdown';

export default TotalBreakdown;
