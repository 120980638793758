import {
  AddCircleOutline,
  ChevronDownIcon,
  RestoreIcon,
  TrashCanIcon,
} from '../../../../assets/icons';
import { ButtonThemes, IconPosition } from '../../../../lib/constants/components';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  GET_CALCULATOR_PRODUCTS,
  GET_PRODUCT_QUALIFICATIONS,
} from '../../../../lib/graphql/Calculator.gql';
import { PreFillOption, ProductGroup, SelectedProduct } from '../../../../lib/types/calculator';

import Accordion from '../../../../components/_shared/Accordion';
import Avatar from '../../../../components/_shared/Avatar';
import Button from '../../../../components/_shared/Button';
import { CalculatorContextActionTypes } from '../../../../lib/contexts/calculator/CalculatorContext.types';
import cx from 'classnames';
import Dropdown from '../../../../components/_shared/Dropdown';
import { SnackbarContextActionTypes } from '../../../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../../../../components/SnackbarContainer/Snackbar/Snackbar.types';
import Spinner from '../../../../components/_shared/Spinner';
import { StepComponentProps } from '../../../../lib/constants/stepper';
import StepsWrapper from '../../../Onboarding/StepsWrapper';
import styles from './ProductSelection.module.scss';
import TextField from '../../../../components/_shared/TextField';
import TotalBreakdown from '../../components/TotalBreakdown';
import { transformProductsByGroup } from '../../../../lib/utils/calculator';
import { useBreakpoint } from '../../../../lib/hooks';
import useCalculatorContext from '../../../../lib/contexts/calculator/useCalculatorContext';
import { useLazyQuery } from '@apollo/client';
import useSnackbarContext from '../../../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

const ProductSelection: FC<StepComponentProps> = ({ next, back }) => {
  const { t } = useTranslation();
  const { isXs } = useBreakpoint();
  const [{ preFill, selectedCrops, selectedProducts, region }, dispatch] = useCalculatorContext();
  const [, dispatchSnackbar] = useSnackbarContext();
  const [groupedProducts, setGroupedProducts] = useState<ProductGroup[]>([]);
  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [touchedFields, setTouchedFields] = useState<Record<number, boolean>>({});
  const [getCalculatorProducts, { loading: loadingProducts }] =
    useLazyQuery(GET_CALCULATOR_PRODUCTS);
  const [getCalculatorQualifications, { loading: loadingQualifications }] = useLazyQuery(
    GET_PRODUCT_QUALIFICATIONS
  );

  const crops = useMemo(
    () => (selectedCrops.includes('Canola') ? [...selectedCrops, 'Oilseeds'] : selectedCrops),
    [selectedCrops]
  );

  useEffect(() => {
    if (!selectedCrops || !preFill || !region) return;

    const handleError = () => {
      dispatchSnackbar({
        type: SnackbarContextActionTypes.AddToQueue,
        payload: { label: t('errors.generic'), state: SnackbarStates.WARNING },
      });
    };

    Promise.all([
      getCalculatorQualifications({ variables: { input: { region } } }),
      getCalculatorProducts({
        variables: { input: { region, prefill: preFill === PreFillOption.YES, crops } },
      }),
    ])
      .then(([qualificationsResponse, productsResponse]) => {
        if (qualificationsResponse?.data) {
          dispatch({
            type: CalculatorContextActionTypes.UpdateProductQualifications,
            payload: {
              productQualifications: qualificationsResponse.data.productQualifications || [],
            },
          });
        }
        if (productsResponse?.data) {
          setGroupedProducts(transformProductsByGroup(productsResponse.data.calculatorProducts, t));
        }
      })
      .catch(handleError);
  }, [
    preFill,
    selectedCrops,
    crops,
    region,
    dispatch,
    getCalculatorProducts,
    dispatchSnackbar,
    getCalculatorQualifications,
    t,
  ]);

  useEffect(() => {
    setIsInputError(selectedProducts.some((product) => !product.sku || !product.acres));
  }, [selectedProducts]);

  const handleAddProduct = (group: string) => {
    dispatch({
      type: CalculatorContextActionTypes.AddSelectedProduct,
      payload: {
        group,
        sku: '',
        name: '',
        crop: '',
        acres: 0,
        pricePerAcre: 0,
      },
    });
  };

  const handleUpdateProduct = (index: number, field: string, value: string | number) => {
    if (field === 'sku') {
      const group = groupedProducts.find((productGroup) =>
        productGroup.options.some((option) => option.value === value)
      );

      const selectedOption = group?.options.find((option) => option.value === value);

      if (selectedOption) {
        dispatch({
          type: CalculatorContextActionTypes.UpdateSelectedProduct,
          payload: {
            index,
            product: {
              sku: value.toString(),
              name: selectedOption.product.name,
              pricePerAcre: selectedOption.product.pricePerAcre,
              crop: selectedOption.product.platform,
              eastUnmatchedCPRates: selectedOption.product.eastUnmatchedCPRates ?? undefined,
              eastSeedTraitMatchRates: selectedOption.product.eastSeedTraitMatchRates ?? undefined,
            },
          },
        });
      }
    } else {
      dispatch({
        type: CalculatorContextActionTypes.UpdateSelectedProduct,
        payload: {
          index,
          product: { [field]: value },
        },
      });
    }
  };

  const handleRemoveProduct = (index: number) => {
    dispatch({
      type: CalculatorContextActionTypes.RemoveSelectedProduct,
      payload: { index },
    });
  };

  const handleResetCalculator = () => {
    dispatch({
      type: CalculatorContextActionTypes.ResetSelectedProduct,
    });
  };

  const renderAccordionContent = (
    group: ProductGroup,
    product: SelectedProduct,
    index: number
  ): ReactNode => {
    const selectedOption = group.options.find((option) => option.value === product.sku);
    const touchState = touchedFields[index];

    return (
      <div className={styles['group-accordion__form']} key={product.sku || `${group.key}-${index}`}>
        <div className={styles['group-accordion__input-wrapper']}>
          <Dropdown
            label={t('bayer-rewards-calculator.product-selector.product-input')}
            name="product-input"
            options={group.options.filter(
              (option) =>
                !selectedProducts.find((selectedProduct) => selectedProduct.sku === option.value)
            )}
            className={styles['group-accordion__product-input']}
            onChange={(e) => handleUpdateProduct(index, 'sku', e.target.value)}
            menuPlacement="bottom"
            value={selectedOption || undefined}
          />

          <TextField
            inputType="number"
            min="0"
            label={t('bayer-rewards-calculator.product-selector.acres-input')}
            name="acre-input"
            className={cx(styles['form-input'], styles['group-accordion__acre-input'], {
              [styles['form-input--error']]: touchState && !product.acres,
            })}
            value={product.acres || ''}
            onChange={(e) =>
              handleUpdateProduct(
                index,
                'acres',
                Math.abs(parseFloat(e.target.value ? e.target.value : 0))
              )
            }
            onBlur={() => setTouchedFields((prev) => ({ ...prev, [index]: true }))}
            hasError={touchState && !!product.sku && !product.acres}
            helperText={
              touchState && !product.acres
                ? t('bayer-rewards-calculator.select-products.input-error')
                : ''
            }
          />
        </div>

        <Button
          icon={TrashCanIcon}
          iconPosition={IconPosition.LEFT}
          theme={ButtonThemes.SECONDARY}
          aria-label={t('bayer-rewards-calculator.product-selector.remove-product')}
          className={styles['group-accordion__remove-product']}
          onClick={() => handleRemoveProduct(index)}
        >
          <span className={!isXs ? 'sr-only' : ''}>{t('general.remove')}</span>
        </Button>
      </div>
    );
  };

  return (
    <StepsWrapper
      title={t('bayer-rewards-calculator.steps.select-products')}
      subTitle={
        <div className={styles['product-selection__subtitle-wrapper']}>
          <h2 className={styles['product-selection__subtitle']}>
            {t('bayer-rewards-calculator.select-products.description')}
          </h2>
          <Button
            theme={ButtonThemes.SECONDARY}
            icon={RestoreIcon}
            onClick={handleResetCalculator}
            disabled={loadingProducts && loadingQualifications}
          >
            {t('bayer-rewards-calculator.select-products.reset-values')}
          </Button>
        </div>
      }
      back={{
        text: t('general.back'),
        disabled: loadingProducts && loadingQualifications,
        onClick: () => {
          scrollTo({ top: 0, behavior: 'smooth' });
          back?.();
        },
      }}
      submit={{
        text: t('bayer-rewards-calculator.select-products.review-calculation'),
        disabled:
          (loadingProducts && loadingQualifications) || isInputError || !selectedProducts.length,
        onClick: () => {
          scrollTo({ top: 0, behavior: 'smooth' });
          next?.();
        },
      }}
      classNames={{
        content: styles['product-selection'],
        wrapper: styles['product-selection__steps-wrapper'],
        form: styles['product-selection__steps-form'],
      }}
    >
      <div className={styles['product-selection__content']}>
        <div className={styles['product-selection__accordion-container']}>
          {loadingProducts && loadingQualifications ? (
            <div className={styles['product-selection__loading']}>
              <Spinner />
            </div>
          ) : (
            <>
              {groupedProducts.map((group, groupIndex) => (
                <div className={styles['group-accordion']} key={group.key}>
                  <Accordion
                    title={
                      <div className={styles['group-accordion__title-wrapper']}>
                        <Avatar firstName={`${groupIndex + 1}`} lastName="" />
                        <div className={styles['group-accordion__text-wrapper']}>
                          <span className={styles['group-accordion__title']}>
                            {group.groupName}
                          </span>
                        </div>
                      </div>
                    }
                    defaultOpen={
                      selectedProducts
                        .map((product) => ({ ...product }))
                        .filter((product) => product.group === group.key).length > 0
                    }
                    Icon={ChevronDownIcon}
                    classNames={{
                      container: styles['group-accordion__container'],
                      button: styles['group-accordion__button'],
                      innerContainer: styles['group-accordion__inner-container'],
                    }}
                  >
                    {selectedProducts
                      .map((product, fullIndex) => ({ ...product, fullIndex }))
                      .filter((product) => product.group === group.key)
                      .map(({ fullIndex, ...product }) =>
                        renderAccordionContent(group, product, fullIndex)
                      )}

                    <Button
                      icon={AddCircleOutline}
                      theme={ButtonThemes.SECONDARY}
                      iconPosition={IconPosition.LEFT}
                      onClick={() => handleAddProduct(group.key)}
                    >
                      {t('bayer-rewards-calculator.product-selector.add-product')}
                    </Button>
                  </Accordion>
                </div>
              ))}
            </>
          )}
        </div>

        <TotalBreakdown />
      </div>
    </StepsWrapper>
  );
};

ProductSelection.displayName = 'ProductSelection';

export default ProductSelection;
