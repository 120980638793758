import { ButtonThemes, IconPosition } from '../../lib/constants/components';
import { ExportType, FileFormat } from '../../lib/constants/exports';
import { FC, useState } from 'react';

import { AxiosResponse } from 'axios';
import cx from 'classnames';
import dayjs from 'dayjs';
import { downloadFile } from '../../lib/utils/api';
import { hyphenSeparatedDate } from '../../lib/constants/date-formats';
import Modal from '../_shared/Modal';
import { SnackbarContextActionTypes } from '../../lib/contexts/snackbar/SnackbarContext.types';
import { SnackbarStates } from '../SnackbarContainer/Snackbar/Snackbar.types';
import { SpinnerIcon } from '../../assets/icons';
import styles from './DownloadListModal.module.scss';
import useSnackbarContext from '../../lib/contexts/snackbar/useSnackbarContext';
import { useTranslation } from 'react-i18next';

export interface Props {
  open: boolean;
  exportType: ExportType;
  onClose(): void;
  exportFile(format: FileFormat): Promise<AxiosResponse<any>>;
}

const DownloadListModal: FC<Props> = ({ open, exportType, onClose, exportFile }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileFormat, setFileFormat] = useState<FileFormat>();
  const [, dispatchSnackbar] = useSnackbarContext();

  const onDownload = (format: FileFormat) => {
    setIsLoading(true);
    setFileFormat(format);
    exportFile(format)
      .then(({ data }) => {
        downloadFile(
          data,
          t(`file-exports.${exportType}`, { date: dayjs().format(hyphenSeparatedDate) }),
          format === FileFormat.PDF
            ? 'application/pdf'
            : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        setIsLoading(false);
        setFileFormat(undefined);
        onClose();
      })
      .catch(() => {
        dispatchSnackbar({
          type: SnackbarContextActionTypes.AddToQueue,
          payload: {
            label: t('errors.generic'),
            state: SnackbarStates.WARNING,
          },
        });
      });
  };

  return (
    <div className={cx(styles['download-list-modal'])}>
      <Modal
        hide={() => onClose()}
        isVisible={open}
        title={t('order-history.download-list')}
        primaryCta={{
          label: t('order-history.xlsx-format'),
          disabled: isLoading,
          action: () => {
            onDownload(FileFormat.XLSX);
          },
          buttonIcon: isLoading && fileFormat === FileFormat.XLSX ? SpinnerIcon : undefined,
          buttonIconClassName: cx({
            [styles['download-list-modal__spinner']]: isLoading && fileFormat === FileFormat.XLSX,
          }),
          buttonIconPosition: IconPosition.LEFT,
        }}
        secondaryCta={{
          label: t('order-history.pdf-format'),
          buttonTheme: ButtonThemes.PRIMARY,
          disabled: isLoading,
          action: () => {
            onDownload(FileFormat.PDF);
          },
          buttonIcon: isLoading && fileFormat === FileFormat.PDF ? SpinnerIcon : undefined,
          buttonIconClassName: cx({
            [styles['download-list-modal__spinner']]: isLoading && fileFormat === FileFormat.PDF,
          }),
          buttonIconPosition: IconPosition.LEFT,
        }}
      >
        <p className={cx(styles['download-list-modal__description'])}>
          {t('order-history.modal-text')}
        </p>
      </Modal>
    </div>
  );
};

DownloadListModal.displayName = 'DownloadListModal';

export default DownloadListModal;
