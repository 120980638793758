import { gql } from '../../__generated__';

export const GET_CALCULATOR_PRODUCTS = gql(`
  query CalculatorProducts($input: CalculatorProductsInput!) {
    calculatorProducts(input: $input) {
      group
      products {
        name
        sku
        pricePerAcre
        mainGroup
        platform
        eastUnmatchedCPRates
        eastSeedTraitMatchRates
      }
    }
  }`);

export const GET_PRODUCT_QUALIFICATIONS = gql(`
  query ProductQualifications($input: CalculatorQualificationsInput!) {
    productQualifications(input: $input) {
      doubleQualifierProducts
      paymentProduct
      qualifierProducts
      segmentRequirement
      segment
    }
  }`);
