import { CalculatorProducts, Region } from '../../__generated__/graphql';
import {
  ProductGroup,
  ProductMainGroup,
  QualifyingAcres,
  RewardsBreakdown,
  SelectedProduct,
} from '../types/calculator';
import { calculationRules as calculationRulesEast } from '../../pages/BayerRewardsCalculator/rules/east';
import { calculationRules as calculationRulesWest } from '../../pages/BayerRewardsCalculator/rules/west';

import { CalculatorContextState } from '../contexts/calculator/CalculatorContext.types';
import { calculatorGroupOrder } from '../constants/calculator';
import { TFunction } from 'i18next';
import { toNormalizeTranslationKey } from './utils';

export const transformProductsByGroup = (
  calculatorProducts: CalculatorProducts[],
  t: TFunction
): ProductGroup[] => {
  const groupOrderMap = new Map(
    calculatorGroupOrder.map(({ groupKey, index }) => [groupKey, index])
  );

  return calculatorProducts
    .map((group) => ({
      key: group.group,
      groupName: t(`product.main-group.${toNormalizeTranslationKey(group.group)}`),
      options: group.products.map((product) => ({
        label: product.name,
        value: product.sku,
        product,
      })),
    }))
    .sort((a, b) => {
      const indexA = groupOrderMap.get(a.key as ProductMainGroup) ?? 10;
      const indexB = groupOrderMap.get(b.key as ProductMainGroup) ?? 10;

      return indexA - indexB;
    });
};

export const calculateQualifyingAcres = (
  products: SelectedProduct[],
  qualifierProducts: string[],
  doubleQualifierProducts: string[]
): QualifyingAcres => {
  const { herbicides, fungicides, seedTreatment, insecticides, trait } = products.reduce(
    (totals, product) => {
      let multiplier = 0;

      if (doubleQualifierProducts.includes(product.sku)) {
        multiplier = 2;
      } else if (qualifierProducts.includes(product.sku)) {
        multiplier = 1;
      }

      if (multiplier > 0) {
        return {
          ...totals,
          herbicides:
            product.group === ProductMainGroup.HERBICIDES
              ? totals.herbicides + product.acres * multiplier
              : totals.herbicides,
          fungicides:
            product.group === ProductMainGroup.FUNGICIDES
              ? totals.fungicides + product.acres * multiplier
              : totals.fungicides,
          seedTreatment:
            product.group === ProductMainGroup.SEED_TREATMENT
              ? totals.seedTreatment + product.acres * multiplier
              : totals.seedTreatment,
          insecticides:
            product.group === ProductMainGroup.INSECTICIDE
              ? totals.insecticides + product.acres * multiplier
              : totals.insecticides,
          trait:
            product.group === ProductMainGroup.TRAIT
              ? totals.insecticides + product.acres * multiplier
              : totals.insecticides,
        };
      }

      return totals;
    },
    { herbicides: 0, fungicides: 0, seedTreatment: 0, insecticides: 0, trait: 0 }
  );

  return { herbicides, fungicides, seedTreatment, insecticides, trait };
};

export const updateRewardsAndCost = (state: CalculatorContextState): CalculatorContextState => {
  const calculationRules =
    state.region === Region.East ? calculationRulesEast : calculationRulesWest;
  const runningTotal = state.selectedProducts.reduce(
    (sum, product) => sum + product.acres * product.pricePerAcre,
    0
  );

  const rewardsBreakdown: RewardsBreakdown = {};
  const estimatedRewards = calculationRules.reduce((rewardSum, rule) => {
    const segmentQualification = state.productQualifications.find(
      (qualification) => qualification.segment === rule.id
    );

    const qualifyingAcres = calculateQualifyingAcres(
      state.selectedProducts,
      segmentQualification?.qualifierProducts || [],
      segmentQualification?.doubleQualifierProducts || []
    );

    if (
      segmentQualification &&
      rule.condition(state.selectedProducts, segmentQualification, qualifyingAcres)
    ) {
      const ruleBreakdown = rule.calculate(
        state.selectedProducts,
        segmentQualification,
        qualifyingAcres
      );

      Object.entries(ruleBreakdown).forEach(([type, details]) => {
        if (!rewardsBreakdown[type]) {
          rewardsBreakdown[type] = {
            reward: 0,
            products: [],
          };
        }

        rewardsBreakdown[type].reward += details.reward;
        rewardsBreakdown[type].products.push(...details.products);
        rewardsBreakdown[type].programUrl = details.programUrl;
      });

      return rewardSum + Object.values(ruleBreakdown).reduce((sum, item) => sum + item.reward, 0);
    }
    return rewardSum;
  }, 0);

  return {
    ...state,
    totalCost: runningTotal,
    totalRewards: estimatedRewards,
    breakdown: rewardsBreakdown,
  };
};
